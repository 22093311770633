import React from "react"
import styled from "styled-components"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"
import Card from "../components/_shared/Card"
import BackgroundImage from "../components/_shared/BackgroundImage"
import RoundedButton from "../components/_shared/RoundedButton"

// ----------------------------------------------------------------------------
const MaintenancePage = props => (
  <Layout className={props.className} layoutMode="maintenance">
    <SEO title="Maintenance" />

    <BackgroundImage
      className="background-container"
      image={require("../images/_shared/chuttersnap.png")}
      width="100%"
    >
      <Card className="card">
        <div className="card-content">
          <h2>
            Il nostro sito è<br />
            in manutenzione.
          </h2>

          <div className="info-container">
            <div className="column first-column">
              <p className="company-name">Salesbridge S.r.l.</p>

              <dl>
                <dt>Indirizzo</dt>
                <dd>Via per Salvatronda, 15</dd>
                <dd>31033 Castelfranco Veneto</dd>
                <dd>Italia</dd>
              </dl>
            </div>

            <div className="column second-column">
              <dl>
                <dt>Mail</dt>
                <dd>
                  <a href={"mailto:contact@salesbridge.it"}>
                    contact@salesbridge.it
                  </a>
                </dd>
              </dl>

              <dl>
                <dt>Telefono</dt>
                <dd>
                  <a href={"tel:+3904381896076"}>0438 1896076</a>
                </dd>
              </dl>
              <dl>
                <dt>Cellulare</dt>
                <dd>
                  <a href={"tel:+393351816547"}>335 1816547</a>
                </dd>
              </dl>
              <dl>
                <dt>Partita IVA</dt>
                <dd>05013730261</dd>
              </dl>
            </div>

            <a className="mobile-btn" href={"tel:+3904381896076"}>
              <RoundedButton type="grey" width="100%">
                CHIAMACI ORA
              </RoundedButton>
            </a>
          </div>
        </div>
      </Card>
    </BackgroundImage>
  </Layout>
)

// ----------------------------------------------------------------------------

const StyledMaintenancePage = styled(MaintenancePage)`
  & {
    > main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .background-container {
        flex: 1;
        width: 100vw;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .info-container {
          margin-top: 22px;
          flex: 1;
          display: flex;
          flex-direction: row;

          .column {
            display: flex;
            flex-direction: column;
          }

          .first-column {
            .company-name {
              margin-bottom: 10px;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
            }

            dl {
              margin: 0;
              font-family: "Poppins ExtraLight";
              font-size: 16px;
              line-height: 22px;

              dd {
                margin: 0;
                font-family: "Poppins";
                font-size: 16px;
                line-height: 22px;
                color: ${props => props.theme.primaryTextColor};
              }
            }
          }

          .second-column {
            flex: 1;
            margin-left: 57px;
            padding-top: 35px;

            dl {
              margin: 0;
              font-family: "Poppins ExtraLight";
              font-size: 16px;
              line-height: 22px;

              dt {
                display: inline;
              }
              dd {
                display: inline;
                margin: 0 0 0 8px;
                font-family: "Poppins";
                font-size: 16px;
                line-height: 22px;

                a {
                  text-decoration: none;
                  color: ${props => props.theme.primaryTextColor};
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }
          }

          .mobile-btn {
            display: none;
          }
        }
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      .card {
        margin: 34px 0;
        box-sizing: border-box;

        width: 90vw;

        .card-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          > h2 {
            text-align: center;
          }

          .info-container {
            display: flex;
            flex-direction: column;

            .column {
              text-align: center;
            }

            .second-column {
              margin-left: 0;
            }

            .mobile-btn {
              margin-top: 34px;
              display: block;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------

export default StyledMaintenancePage
